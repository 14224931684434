export default {
  props: {
    formKey: String,
    blockIndex: Number,
    formDataItemKey: String,
    formDataItem: Object,
    blockReadonly: Boolean,
    blockDisabled: Boolean,
  },
  data: () => ({
    data: null,
  }),
  created() {
    this.$root.$on(`form/${this.formKey}/beforeSave`, this.beforeSave)
  },
  methods: {
    // 透過共通的表單資料元件clipboard上傳的圖片物件, 由各元件需求自行實做覆蓋此mixin metho
    appendPhoto(photo) {
      // TODO
    },
    beforeSave() {
      this.syncFinishStatus()
    },
    // 同步該欄位資料完成狀態
    syncFinishStatus() {
      this.$store.dispatch(`form/${this.formKey}/setDataFinishedStatus`, {
        key: this.formDataItemKey,
        finished: this.isFinished,
      })
    },
    async sync() {
      await this.$nextTick()
      this.$store.dispatch(`form/${this.formKey}/setDataColumn`, {
        key: this.formDataItemKey,
        value: this.data,
      })
    },
    bothEmpty(value1, value2) {
      const value1Empty = window.eagleLodash.isEmpty(value1)
      const value2Empty = window.eagleLodash.isEmpty(value2)
      if(!value1Empty) return false
      if(!value2Empty) return false
      return true
    },
    photoListEqual(photos1, photos2) {
      if(this.bothEmpty(photos1, photos2)) return true
      photos1 = window.helper.getComputedPhotoList(photos1)
      photos2 = window.helper.getComputedPhotoList(photos2)
      return window.eagleLodash.isEqual(photos1, photos2)
    },
    arrayPropertyDiff(array1, array2, property) {
      if(!Array.isArray(array1)) array1 = []
      if(!Array.isArray(array2)) array2 = []
      const computedArray1 = array1.map(item => {
        if(typeof property === 'function') return property(item)
        return window.eagleLodash.get(item, property)
      })
      const computedArray2 = array2.map(item => {
        if(typeof property === 'function') return property(item)
        return window.eagleLodash.get(item, property)
      })
      return window.eagleLodash.isEqual(computedArray1, computedArray2) === false
    },
    async copyData() {
      try {
        await this.$copyText(window.eagleLodash.cloneDeep(this.copyDataContent))
        this.$snotify.success(null, this.$t('copy.successfully'))
      } catch (error) {
        console.warn(error)
      }
    },
  },
  computed: {
    copyDataContent() {
      if(this.storeData instanceof Object) return JSON.stringify(this.storeData)
      if(Array.isArray(this.storeData)) return JSON.stringify(this.storeData)
      return this.storeData
    },
    hasWritePermission() {
      return this.$store.getters[`form/${this.formKey}/hasWritePermission`]
    },
    isFinished() {
      if(!this.requiredFinished) return false
      if(!this.customValidateResult) return false
      return true
    },
    // 是否為必填欄位
    required() {
      if(!this.formDataItem.required) return null
      if(typeof this.formDataItem.required != 'function') {
        return this.formDataItem.required === true
      }
      return this.formDataItem.required(this.formInfo)
    },
    requiredFinished() {
      if(!this.required) return true
      return this.$helper.validator.required(this.data)
    },
    // vuetify驗證, 各元件有需要可覆寫此屬性, 並使用this.baseVuetifyRules
    vuetifyRules() {
      return this.baseVuetifyRules
    },
    // 基本vuetify驗證
    baseVuetifyRules() {
      let rules = []
      if(this.required) {
        rules.push(value => this.requiredFinished || this.$t('validate.error.required'))
      }
      if(this.customValidateRules) {
        rules = rules.concat(this.customValidateRules)
      }
      return rules
    },
    // 自訂的validate轉成的vuetify rule
    customValidateRules() {
      if(typeof this.formDataItem.validate != 'function') return null
      const rules = this.formDataItem.validate(this.formInfo)
      if(!Array.isArray(rules)) return null
      const result = rules.map(rule => {
        const error = !rule.error ? null : this.$t(rule.error)
        return value => rule.result || error
      })
      return result
    },
    // 自訂的validate結果
    customValidateResult() {
      if(typeof this.formDataItem.validate != 'function') return true
      const rules = this.formDataItem.validate(this.formInfo)
      if(!Array.isArray(rules)) return true
      for(const rule of rules) {
        if(rule.result === false) return false
      }
      return true
    },
    formError() {
      return this.$store.getters[`form/${this.formKey}/error`]
    },
    formItemError() {
      const properties = this.formDataItemKey.split('.')
      return window.eagleLodash.get(this.formError, properties)
    },
    error() {
      if(!this.formItemError) return null
      return this.$t(this.formItemError)
    },
    readonly() {
      if(this.blockReadonly === true) return true
      if(this.formDataItem.readonly === true) return true
      if(typeof this.formDataItem.readonly === 'function') {
        return this.formDataItem.readonly(this.formInfo)
      }
      return false
    },
    photoManager() {
      if(this.formDataItem.photoManager === true) return true
      return false
    },
    disabled() {
      if(!this.hasWritePermission) return true
      if(this.blockDisabled === true) return true
      if(this.formDataItem.disabled === true) return true
      if(typeof this.formDataItem.disabled === 'function') {
        return this.formDataItem.disabled(this.formInfo)
      }
      return false
    },
    formInfo() {
      return this.$store.getters[`form/${this.formKey}/info`]
    },
    formMode() {
      return this.$store.getters[`form/${this.formKey}/mode`]
    },
    formActions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    formMeta() {
      return this.$store.getters[`form/${this.formKey}/meta`]
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    formOriginalData() {
      return this.$store.getters[`form/${this.formKey}/originalData`]
    },
    formData: {
      get() {
        return this.$store.getters[`form/${this.formKey}/data`]
      },
      set(value) {
        this.$store.dispatch(`form/${this.formKey}/setData`, value)
      },
    },
    storeData() {
      const properties = this.formDataItemKey.split('.')
      return window.eagleLodash.get(this.formData, properties)
    },
    originalData() {
      const properties = this.formDataItemKey.split('.')
      return window.eagleLodash.get(this.formOriginalData, properties)
    },
    focus() {
      return this.formDataItem.focus === true
    },
    hasChanged() {
      if (typeof this.formDataItem.hasChanged != 'function') return false
      const formDataChangeHelper = window.eagleLodash.cloneDeep({
        formMode: this.formMode,
        formMeta: this.formMeta,
        formConfig: this.formConfig,
        formData: this.formData,
        data: this.data,
        originalData: this.originalData,
        bothEmpty: this.bothEmpty,
        arrayPropertyDiff: this.arrayPropertyDiff,
        photoListEqual: this.photoListEqual,
      })
      return this.formDataItem.hasChanged(formDataChangeHelper)
    },
    label() {
      if(this.hideLabel) return null
      const label = this.$t(this.formDataItem.label)
      if(this.required) return `* ${label}`
      return label
    },
    hideDetails() {
      return this.formDataItem.hideDetails
    },
    hideLabel() {
      return this.formDataItem.hideLabel
    },
  },
  watch: {
    hasChanged: {
      immediate: true,
      deep: true,
      handler() {
        this.$store.dispatch(`form/${this.formKey}/setDataChangedStatus`, {
          key: this.formDataItemKey,
          changed: this.hasChanged,
        })
      },
    },
    storeData: {
      deep: true,
      handler() {
        if(typeof this.init === 'function') {
          this.init()
        }
      },
    },
  },
}
